<template>
  <q-card class="content-center" flat>
    <q-img src="https://rpgecom.com/wp-content/uploads/2019/07/RPGECOM_LOGO.png"/>
    <h4>
        Welcome back {{ name }}
    </h4>
  </q-card>
</template>

<style>
</style>

<script>
export default {
  name: 'Welcome',
  data() {
    return {
      name: 'Palm'
    }
  }
}
</script>

<style scoped>

</style>


