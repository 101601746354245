import { render } from "./Welcome.vue?vue&type=template&id=ca356b60&scoped=true"
import script from "./Welcome.vue?vue&type=script&lang=js"
export * from "./Welcome.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-ca356b60"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QImg});
