import { render } from "./Home.vue?vue&type=template&id=5e27509c&scoped=true"
import script from "./Home.vue?vue&type=script&lang=js"
export * from "./Home.vue?vue&type=script&lang=js"

import "./Home.vue?vue&type=style&index=0&id=5e27509c&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-5e27509c"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection});
