<template>
  <div>

    <div class="cards">
      
      <q-card class="my-card" @click="goToMarketTracker">
        <img class="card-img"
             src="https://s27389.pcdn.co/wp-content/uploads/2018/01/AdobeStock_171462504-1024x576.jpeg">
        <q-card-section>
          <div class="text-h6 mt-headline">Market Tracker</div>
          <div class="text-subtitle2">Explore and track products prices, market share and way more.</div>
        </q-card-section>
      </q-card>


      <q-card class="my-card sp-card" @click="goToSellingPartner">
        <img class="card-img" src="https://img.rasset.ie/0016de33-500.jpg">
        <q-card-section>
          <div class="text-h6 mt-headline">Selling Parnter</div>
          <div class="text-subtitle2 sp-sub">Keep track after your own store.</div>
        </q-card-section>
      </q-card>

      <q-card class="my-card sp-card" @click="goToProductOpp">
        <img class="card-img"
             src="https://www.callcentrehelper.com/images/stories/2019/05/green-five-star-vector-760.png">
        <q-card-section>
          <div class="text-h6 mt-headline">Product Opportunity</div>
          <div class="text-subtitle2 po-sub ">Get reliable information of profitable products.</div>
        </q-card-section>
      </q-card>

    </div>


  </div>
</template>

<script>
export default {
  name: "Headline",
  methods: {
    goToMarketTracker() {
      this.$router.push('/market-tracker');
    },
    goToSellingPartner() {
      this.$router.push('/dashboard'); //not exist yet
    },
    goToProductOpp() {
      this.$router.push('/product-opp'); //not exist yet
    }
  }
}
</script>

<style scoped>

.my-card {
  background: #ecebd3;
  width: 500px;
  margin-left: 100px;
  border-radius: 20px;
}


.my-card:hover {
  cursor: pointer;
}

.cards {
  display: flex;
}

.card-img {
  height: 350px;
}

</style>
