import { render } from "./Navigator.vue?vue&type=template&id=5bf4133f&scoped=true"
import script from "./Navigator.vue?vue&type=script&lang=js"
export * from "./Navigator.vue?vue&type=script&lang=js"

import "./Navigator.vue?vue&type=style&index=0&id=5bf4133f&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-5bf4133f"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection});
