<template>
  <q-card flat>
    <q-card-section class="title">
      <Welcome msg="Welcome to Your Vue.js App"/>
    </q-card-section>

    <q-card-section>
      <Navigator/>
    </q-card-section>
  </q-card>

</template>

<script>
// @ is an alias to /src
import Welcome from '../components/navigator/Welcome'
import Navigator from "../components/navigator/Navigator";

export default {
  name: 'Home',
  components: {
    Welcome, Navigator
  }
}
</script>

<style scoped>

.title {
  margin-top: 1.25px;
  margin-bottom: 1.25px;
  width: 400px;
  margin-left: 44%;
  max-height: 200px;
}
.welcome{
  margin-left: 40px
}

</style>

